.Inventar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    box-sizing: border-box;
    margin: 0;
    position: relative;
    overflow: auto;
}

.Inventar table {
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    border-collapse: collapse;
}

.Inventar table , .Inventar th , .Inventar td , .Inventar thead , .Inventar tbody , .Inventar tr {
    border: 1px solid #000;
    border-collapse: collapse;
    text-align: center;
}