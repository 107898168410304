.detail_Inventar {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    margin: 0;
    position: relative;
    overflow: auto;
}

.detail_Inventar * {
    padding: 5px;
    margin: auto !important;
}

.detail_Inventar label {
    width: 100%;
}

.detail_Inventar input {
    width: 100%;
    height: max-content;
    font-size: normal;
    border: 1px solid #000;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.detail_Inventar .button {
    height: max-content;
    font-size: normal;
    border: 1px solid #000;
    border-radius: 5px;
    padding: auto !important;
    margin-top: 10px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: #fff;
    color: #000;
    font-weight: bold;
    cursor: pointer;

}

