

.Grundeinstellung * {
  padding: 5px;
  margin: auto !important;
}

.Grundeinstellung label {
  width: 100%;
}

.Grundeinstellung input {
  width: 100%;
  height: max-content;
  font-size: normal;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

}



