.Usersettings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    padding: 0;
    margin: 0;
}

.Usersettings input {
    width: 100%;
    text-align: center;
    height: max-content;
    font-size: normal;
    border: 1px solid #000;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.Usersettings .button {
    height: max-content;
    font-size: normal;
    border: 1px solid #000;
    border-radius: 5px;
    padding: auto !important;
    margin-top: 10px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: #fff;
    color: #000;
    font-weight: bold;
    cursor: pointer;

}