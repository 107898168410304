 table.Warenkorb * {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
}

.Summen p{
    padding: auto;
    margin: 0 !important;
}

.Summen p:first-child{
    font-weight: bold;
}

.Summen p:last-child{
    padding-bottom: 2%;
}

.QR-Code *{

   width: 100%;
    height: 90%;

}

.abschließen *{
    
    margin-left: 2% !important;
}

.Kopf label {
        display: block;
        width: 100%;
        margin: 4%;
        font-size: 12px;
      }

.Kopf select {

        margin: 4%;
        font-size: normal;
      }

.Kopf{
    display: block !important;;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10%;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 0.1% 2%;
}