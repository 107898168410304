.Add_User {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.Add_User input {
  width: 100%;
  text-align: center;
  height: max-content;
  font-size: normal;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.Add_User .button {
  height: max-content;
  font-size: normal;
  border: 1px solid #000;
  border-radius: 5px;
  padding: auto !important;
  margin-top: 10px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}