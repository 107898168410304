.Einstellung{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
}

.Einstellung * {
    margin: 1% !important;
   
}

