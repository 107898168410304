.User-Table table , .User-Table th, .User-Table td {
    border: 1px solid black !important;
    border-collapse: collapse !important;
    text-align: center !important;
    padding: auto !important;
    width: 100% ;
    height: 100% ;
    margin: auto !important;
    

}