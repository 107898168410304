.NeuerArikel{
    margin:  auto;
    margin-top: 2% !important;
    width: max-content;
    padding: 5px;
    height: auto;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.NeuerArikel * {
    padding: 5px;
    margin: auto !important;
}

.NeuerArikel .button {
    align-self: start;
    margin: auto !important;
    margin-top: 2% !important;
    width: 100%;
    height: 50px;
    background-color: #f5f5f5;
    border: 1px solid #000;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}