.Beleg{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    overflow: auto;
}

.container{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    background-color: #f5f5f5;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    overflow: auto;
}

.Belege{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    padding: 0 0 0 0;
    margin-top: 10px;
    overflow: auto;
}

.Belege table , .Belege th , .Belege td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px;
    text-align: center;
}

.Beleg {
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: 10px 0 0 0;
}

.Beleg iframe{
    width: 100%;
    height: 100%;
    overflow: auto;

}

.Beleg-QR {
    width: 100%;
    height: 100%;
    margin: 10px 0 0 0;
}

.BelegButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 10%;
    background-color: #f5f5f5;
    padding: auto;
    border-radius: 1%;
    margin: auto;
    margin-bottom: 10px;
    overflow: auto;
    font-size: x-large;
}



.Datumsauswahl {
    border: black solid 1px;
}

.Datumsauswahl *{
    margin : 5px;
}



