.Übersicht  {
    padding: 5px;
    margin: auto !important;
    margin-top: 2% !important;
    width: 100vw;
}

.Übersicht *  {
    padding: 5px;
    margin: auto !important;

}

.Übersicht table {
    width: 100%;
}

.Übersicht button  {
    align-self: start;
    margin: auto !important;
    margin-top: 2% !important;
    width: 100%;
    height: 10vh;
    background-color: #f5f5f5;
    border: 1px solid #000;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}