
 .Artikel_hinzufügen *{

    font-size: xx-large;
 }
  .value-button {
    display: inline-block;
    border: 1px solid #ddd;
    margin: 0px;
    width: 40px;
    height: 50px;
    font-size: normal !important;
    text-align: center;
    vertical-align: middle;
    padding: 11px 0;
    background: #eee;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .value-button:hover {
    cursor: pointer;
  }
  
  form #decrease {
    margin-right: -4px;
    border-radius: 8px 0 0 8px;
  }
  
  form #increase {
    margin-left: -4px;
    border-radius: 0 8px 8px 0;
  }
  
  form #input-wrap {
    margin: 0px;
    padding: 0px;
  }
  
  input#number {
    text-align: center;
    border: none;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin: 0px;
    width: 40px;
    height: 55px;
  }
  
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }